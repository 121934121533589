import React, { useCallback, useMemo } from 'react';
import { FlowContextWrapper } from '@getvim/flow-context-provider';
import { gqlClient } from '@getvim/vim-connect/src/infra/common/gql-client';
import { Loader } from '@getvim/atomic-ui';
import { useVimAuth } from '@getvim/internal-vim-os-sdk/react';
import { internalApi } from '../api';
import { createLogger } from '../utils';

export const RequiredStateWrapper: React.FC = ({ children }) => {
  const logger = createLogger('RequiredStateWrapper');

  const { accessToken } = useVimAuth();
  const mandatoryState = useMemo(() => {
    if (accessToken) {
      internalApi.referralUtilityApi.setAccessToken(accessToken);
      internalApi.optumNoPIIApi.setAccessToken(accessToken);
      gqlClient.setAccessToken(accessToken);
    }
    return {
      accessToken: Promise.resolve(accessToken),
    };
  }, [accessToken]);

  const onError = useCallback(() => {
    logger.error('Failed to fetch state in App RequiredStateWrapper', {
      mandatoryState,
      noPHI: true,
    });
  }, [logger, mandatoryState]);

  return (
    <FlowContextWrapper loader={<Loader type="dots" />} state={mandatoryState} onError={onError}>
      {children}
    </FlowContextWrapper>
  );
};
