import { gql } from '@apollo/client';

export const REFERRAL_UTILITY_REQUEST_QUERY = gql`
  query ReferralUtilityRequest($input: ReferralUtilityRequestInput!) {
    referralUtilityRequest(input: $input) {
      referenceId
      redirectUrl
    }
  }
`;

export const REFERRAL_UTILITY_RESPONSE_QUERY = gql`
  query ReferralUtilityResponse($input: ReferralUtilityResponseInput!) {
    referralUtilityResponse(input: $input) {
      referralId
      clinicalAlgorithm {
        clinicalAlgorithmResponses {
          question
          response
        }
        code
        reasonAlgorithmIgnored
        reasonCommentAlgorithmIgnored
      }
      specialist {
        identifiers {
          code
          value
        }
        firstName
        lastName
        address
        clinicLocation
        fullAddress {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        phone
        fax
      }
      specialtyMappingDescription
      ruSpecialty
      type
      numberOfVisits
      beginDate
      endDate
      diagnosisCodes {
        value
        description
      }
      referralReason
      urgency
      provider {
        identifiers {
          system
          code
          value
        }
      }
    }
  }
`;

export const REFERRAL_UTILITY_PUT_QUERY = gql`
  query ReferralUtilityPut($input: ReferralUtilityPutInput!) {
    referralUtilityPut(input: $input) {
      isSuccess
    }
  }
`;

export const VALIDATE_PATIENT_QUERY = gql`
  query ValidatePatient($input: ValidatePatientRequestInput!) {
    validatePatient(input: $input) {
      isSuccess
    }
  }
`;
