import {
  ButtonCalculatedPayload,
  ButtonClickedPayload,
  ReferralSubmittedPayload,
  TargetSelectNativeProviderPayload,
  TargetSelectVimProviderPayload,
} from './analyticPayloads.types';

export enum OptumReferralAppEventsEnum {
  BUTTON_CALCULATED = 'vim_select_button_calculated',
  TARGET_SELECTED_NATIVE = 'target_selected_native',
  TARGET_SELECTED_WITH_VIM = 'target_selected_with_vim',
  VIM_SELECT_BUTTON_CLICKED = 'vim_select_button_clicked',
  RU_REFERRAL_SUBMITTED = 'ru_referral_submitted',
}

type OptumReferralAppEvents = {
  [OptumReferralAppEventsEnum.RU_REFERRAL_SUBMITTED]: ReferralSubmittedPayload;
  [OptumReferralAppEventsEnum.BUTTON_CALCULATED]: ButtonCalculatedPayload;
  [OptumReferralAppEventsEnum.VIM_SELECT_BUTTON_CLICKED]: ButtonClickedPayload;
  [OptumReferralAppEventsEnum.TARGET_SELECTED_NATIVE]: TargetSelectNativeProviderPayload;
  [OptumReferralAppEventsEnum.TARGET_SELECTED_WITH_VIM]: TargetSelectVimProviderPayload;
};

type AnalyticsEvents = OptumReferralAppEvents;

export type OptumReferralAppAnalyticsEvents = AnalyticsEvents;
