import React, { FC, memo } from 'react';

interface ScreenRendererProps {
  url?: string | null;
}

const IframeRenderer: FC<ScreenRendererProps> = memo(({ url }) => {
  return <>{url ? <iframe className="widget-frame-hub" src={url} /> : <ErrorLoadIframe />}</>;
});

const ErrorLoadIframe: FC = () => (
  <div className="error-load-iframe">
    <div className="error-load-iframe-title">Something went wrong :(</div>
    <div className="error-load-iframe-text">Please refresh the page and try again</div>
    <img
      className="error-load-iframe-img"
      src="https://static.getvim.com/img/errorPageImg.png"
      alt="loading logo"
    />
  </div>
);

IframeRenderer.displayName = 'IframeRenderer';

export default IframeRenderer;
