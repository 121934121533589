import { gql } from '@apollo/client';

export const GET_REFERRAL_UTILITY_CONFIG_QUERY = gql`
  query ReferralUtilityGetConfiguration {
    referralUtilityGetConfiguration {
      configuration
      organization {
        tag
      }
    }
  }
`;

export const SAVE_REFERRAL_UTILITY_CONFIG_QUERY = gql`
  mutation ReferralUtilityPutConfiguration($input: ReferralUtilityPutConfiguration!) {
    referralUtilityPutConfiguration(input: $input) {
      configuration
    }
  }
`;
