import {
  REFERRAL_UTILITY_REQUEST_QUERY,
  REFERRAL_UTILITY_RESPONSE_QUERY,
  REFERRAL_UTILITY_PUT_QUERY,
  VALIDATE_PATIENT_QUERY,
} from './queries';
import { BaseInternalApi } from '../baseApi';
import {
  ReferralUtilityResponseInput,
  ReferralUtilityIframeResult,
  ReferralUtilityResponseReferralResult,
  ReferralUtilitySaveResult,
  ReferralUtilitySaveInput,
  ValidatePatientInput,
  ReferralUtilityNoPIIRequestInput,
  ValidatePatientResult,
} from './types';

export class OptumNoPIIApi extends BaseInternalApi {
  constructor() {
    super('optumNoPIIApi', '/optum-referral/api/graphql');
  }

  public async openReferralUtilityIframeNoPII(
    input: ReferralUtilityNoPIIRequestInput,
  ): Promise<ReferralUtilityIframeResult | undefined> {
    const gqlClient = await this.getClient();

    const { data } = await gqlClient.query<
      { referralUtilityRequest: ReferralUtilityIframeResult },
      { input: ReferralUtilityNoPIIRequestInput }
    >({
      query: REFERRAL_UTILITY_REQUEST_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return data?.referralUtilityRequest;
  }

  public async getReferralResponseFromOptum(
    input: ReferralUtilityResponseInput,
  ): Promise<ReferralUtilityResponseReferralResult | undefined> {
    const gqlClient = await this.getClient();

    const { data } = await gqlClient.query<
      { referralUtilityResponse: ReferralUtilityResponseReferralResult },
      { input: ReferralUtilityResponseInput }
    >({
      query: REFERRAL_UTILITY_RESPONSE_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return data?.referralUtilityResponse;
  }

  public async sendReferralSaveInEhr(input: ReferralUtilitySaveInput): Promise<boolean> {
    const gqlClient = await this.getClient();

    const { data } =
      (await gqlClient.query<ReferralUtilitySaveResult, { input: ReferralUtilitySaveInput }>({
        query: REFERRAL_UTILITY_PUT_QUERY,
        variables: { input },
        fetchPolicy: 'no-cache',
      })) ?? {};
    return data?.referralUtilityPut?.isSuccess;
  }

  public async validatePatient(input: ValidatePatientInput): Promise<boolean> {
    const gqlClient = await this.getClient();

    const { data } =
      (await gqlClient.query<ValidatePatientResult, { input: ValidatePatientInput }>({
        query: VALIDATE_PATIENT_QUERY,
        variables: { input },
        fetchPolicy: 'no-cache',
      })) ?? {};
    return data?.validatePatient?.isSuccess;
  }
}
