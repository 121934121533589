import {
  IInternalResourceUpdater,
  UpdatableReferral,
  UpdatableReferralField,
} from '@getvim/internal-vim-os-sdk/types';

export function buildReferralWriteback(
  builder: ReturnType<IInternalResourceUpdater['referralBuilder']>,
  referralToUpdate: UpdatableReferral,
) {
  const fields: UpdatableReferralField[] = Object.keys(
    referralToUpdate,
  ) as UpdatableReferralField[];

  for (const field of fields) {
    switch (field) {
      case 'targetProvider':
        builder.setTargetProvider(referralToUpdate[field]!);
        break;
      case 'basicInformation': {
        const subFields = Object.keys(referralToUpdate[field] || {});

        for (const subField of subFields) {
          switch (subField) {
            case 'specialty': {
              if (referralToUpdate.basicInformation?.specialty) {
                builder.setSpecialty(referralToUpdate.basicInformation?.specialty);
              }
              break;
            }

            case 'endDate': {
              builder.setEndDate(referralToUpdate.basicInformation?.endDate);
              break;
            }

            case 'startDate': {
              builder.setStartDate(referralToUpdate.basicInformation?.startDate);
              break;
            }

            case 'priority': {
              builder.setPriority(referralToUpdate.basicInformation?.priority);
              break;
            }

            case 'numberOfVisits': {
              builder.setNumberOfVisits(referralToUpdate.basicInformation?.numberOfVisits);
              break;
            }

            case 'reasons': {
              builder.setReasons(referralToUpdate.basicInformation?.reasons);
              break;
            }

            case 'notes': {
              builder.setNotes(referralToUpdate.basicInformation?.notes);
              break;
            }
          }
        }
        break;
      }
      case 'conditions': {
        const subFields = Object.keys(referralToUpdate[field] || {});

        for (const subField of subFields) {
          switch (subField) {
            case 'diagnosis': {
              builder.setDiagnosis(referralToUpdate.conditions?.diagnosis);
              break;
            }
          }
        }
        break;
      }
    }
  }
}
