import { ReferralPriority } from '@getvim-os/types';
import { getProviderBySpecialist } from './getPoviderBySpecialist';
import { formatReason, getFormatResponseToNotes } from './getNotesFormatting';
import { UpdatableReferral } from '@getvim/internal-vim-os-sdk/types';
import { createLogger } from '../../utils';

const logger = createLogger('getUpdateReferralByQueryResponse');

export const getUpdateReferralByQueryResponse = async (
  data: any, //ReferralUtilityResponseReferralResult,
  shouldUseLeadingReachProvider: boolean,
): Promise<UpdatableReferral> => {
  const {
    specialist,
    specialtyMappingDescription: specialty,
    beginDate,
    endDate,
    referralReason,
    urgency,
    diagnosisCodes,
    numberOfVisits,
    provider,
  } = data;

  const targetProviderPayload = getProviderBySpecialist(specialist, specialty);

  logger.info('Before Leading Reach Provider modification', {
    noPHI: true,
    shouldUseLeadingReachProvider,
    targetProviderPayload: targetProviderPayload?.demographics,
  });

  if (shouldUseLeadingReachProvider && provider) {
    const { identifiers } = provider;
    if (identifiers.length) {
      (targetProviderPayload as any).demographics = {
        firstName: '',
        lastName: identifiers[0].value,
      };
    }
  }

  logger.info('After Leading Reach Provider modification', {
    noPHI: true,
    shouldUseLeadingReachProvider,
    targetProviderPayload: targetProviderPayload?.demographics,
  });
  return {
    targetProvider: targetProviderPayload!,
    basicInformation: {
      startDate: beginDate,
      endDate,
      reasons: referralReason ? [formatReason(referralReason)] : undefined,
      priority: ReferralPriority[urgency?.toUpperCase()],
      specialty,
      notes: await getFormatResponseToNotes(data),
      numberOfVisits,
    },
    conditions: {
      diagnosis: diagnosisCodes?.map(({ description, value }) => {
        return { description, code: value };
      }),
    },
  };
};
