import { AppConfig } from '../../types/index';
import { internalApi } from '../../api';
import type { ReferralWithoutPII, PatientWithoutPII } from '@getvim/internal-vim-os-sdk/types';
import { stringToLower } from '../../utils';

export const isReferralValid = async ({
  referral,
  patient,
}: {
  referral: ReferralWithoutPII;
  patient: PatientWithoutPII;
}): Promise<boolean> => {
  const { referringProvider } = referral;
  const { token } = patient;

  const isPatientValid = await internalApi.optumNoPIIApi.validatePatient({ patient: { token } });

  const required = [
    referringProvider?.demographics?.firstName,
    referringProvider?.demographics?.lastName,
    isPatientValid,
  ];
  return required.every((item) => !!item);
};

export const isPatientEligible = ({
  patient,
  insurancesPlanList,
  supportAllInsurances,
}: {
  patient: PatientWithoutPII | undefined;
  insurancesPlanList: AppConfig['insurancesPlanList'];
  supportAllInsurances: AppConfig['supportAllInsurances'];
}): boolean => {
  if (!patient) return false;

  const patientInsuranceLowerCaseTransformed = stringToLower(patient.insurance?.ehrInsurance);
  const insurancesPlansLowerCaseTransformed =
    insurancesPlanList?.map((item) => stringToLower(item)) || [];

  return (
    supportAllInsurances ||
    !!(
      patientInsuranceLowerCaseTransformed &&
      insurancesPlansLowerCaseTransformed?.includes(patientInsuranceLowerCaseTransformed)
    )
  );
};
