import { internalApi } from '../../api';
import type { ReferralWithoutPII } from '@getvim/internal-vim-os-sdk/types';

const referralResponse = async ({
  optumReferralId,
  deployConfig,
}: {
  optumReferralId: string;
  deployConfig: string;
  referral: ReferralWithoutPII | undefined;
}) => {
  return await internalApi.optumNoPIIApi.getReferralResponseFromOptum({
    id: optumReferralId,
    deployConfig,
  });
};

export { referralResponse };
export { getUpdateReferralByQueryResponse } from './getUpdateReferralByQueryResponse';
