import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import '@getvim/atomic-ui/assets/styles/main.less';
import { ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './components/App';
import { RequiredStateWrapper } from './components/RequiredStateWrapper';
import 'bootstrap/less/bootstrap.less';
import './index.less';
import { GlobalStateProvider } from './stores/GlobalStore';
import ConfigurationComponent from './components/configuration';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { featureFlagsConfig } from './utils/featureFlags';

const AppSdkWrapper = () => {
  return (
    <VimSDKProvider>
      <ThemeVariablesWrapper theme={themes.optum}>
        <GlobalStateProvider>
          <RequiredStateWrapper>
            <FeatureFlagProvider {...featureFlagsConfig}>
              <App />
            </FeatureFlagProvider>
          </RequiredStateWrapper>
        </GlobalStateProvider>
      </ThemeVariablesWrapper>
    </VimSDKProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <AppSdkWrapper />,
    index: true,
  },
  {
    element: <ConfigurationComponent />,
    path: '/console-ui',
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);
