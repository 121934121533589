import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { ReferralWithoutPII } from '@getvim/internal-vim-os-sdk/types';
import { useMemo } from 'react';
import { parseVimProviderToAnalyticsProvider } from '../analytics/parseProviderForAnalytics';
import { OptumReferralAppAnalyticsEvents } from '../analytics/types/appEvents.types';

let baseProperties = {};

export const useAnalytics = () => {
  const { trackAnalytics } = useApi();
  const analyticsClient = useMemo(() => {
    const upsertBaseProperties = (properties: any) => {
      baseProperties = { ...baseProperties, ...properties };
    };
    const track = <T extends keyof OptumReferralAppAnalyticsEvents>(
      event: T,
      properties: any[T] & { referral?: ReferralWithoutPII },
    ) => {
      const { diagnosis, specialty, referringProvider, targetProvider } = properties.referral ?? {};
      const referralProperties = properties.currentReferral
        ? {
            speciality: specialty,
            referringProvider: parseVimProviderToAnalyticsProvider(referringProvider),
            targetProvider: parseVimProviderToAnalyticsProvider(targetProvider),
            icd: diagnosis,
          }
        : {};
      trackAnalytics({
        event,
        properties: { ...baseProperties, ...properties, ...referralProperties },
      });
    };

    return { track, upsertBaseProperties };
  }, [trackAnalytics]);

  return { analyticsClient };
};
