import { IdentifiersSpecialistResponse } from '../../api/optum/types';

const providerNpiCode = 'RU';

export const getNpiIdentifierResponse = (
  identifiers: [IdentifiersSpecialistResponse] | undefined,
): string | undefined => {
  if (!identifiers) return '';
  return identifiers.find((curr) => curr.code === providerNpiCode)?.value;
};
