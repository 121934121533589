import { Provider } from '@getvim/internal-vim-os-sdk/types';
import { SpecialtiesResponse } from '../../api/optum/types';
import { getNpiIdentifierResponse } from './getNpiByIdentifierResponse';
import { createLogger } from '../../utils';

const logger = createLogger('getProviderBySpecialist');

const NA_PROVIDER_NAME = 'N/A';

export const getProviderBySpecialist = (
  specialist?: SpecialtiesResponse,
  specialtyDescription?: string,
): Omit<Provider, 'ehrProviderId'> | undefined => {
  if (!specialist) {
    return undefined;
  }

  const {
    firstName,
    lastName,
    identifiers,
    address: fullAddress,
    fullAddress: address,
    fax,
    phone,
    clinicLocation,
  } = specialist;
  let demographics = {
    firstName,
    lastName,
  };

  const npi = getNpiIdentifierResponse(identifiers);
  const { addressLine1: address1, addressLine2: address2, city, state, zipCode } = address || {};

  const isEmptyName =
    (!firstName || firstName.toUpperCase() === NA_PROVIDER_NAME) &&
    (!lastName || lastName.toUpperCase() === NA_PROVIDER_NAME);

  if (isEmptyName && clinicLocation) {
    logger.info('Provider name is N/A, use clinic name', {
      noPHI: true,
      specialist,
      npi: `${npi}`,
    });
    demographics = {
      firstName: '',
      lastName: clinicLocation,
    };
  }

  return {
    npi,
    demographics,
    specialty: specialtyDescription ? [specialtyDescription] : [],
    facility: {
      name: clinicLocation,
      address: { fullAddress, address1, address2, city, state, zipCode },
      contact_info: { faxNumber: fax, mobilePhoneNumber: phone },
    },
  };
};
