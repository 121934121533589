export function removeNullsValueProps(obj: any): any {
  if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
    return Object.entries(obj)
      .filter(([_key, value]) => value !== null)
      .reduce((acc, [key, value]) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
          acc[key] = removeNullsValueProps(value);
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
  }
  return obj;
}
