import { Screens } from '../types';

export type GlobalState = {
  url?: string | null;
  screen: Screens;
  optumIframeId?: string;
  lastAction?: {
    type: GlobalStateActionType;
    timestamp?: number;
  };
};

export enum GlobalStateActionType {
  REFERRAL_LOADING = 'REFERRAL_LOADING',
  REFERRAL_CLOSED = 'REFERRAL_CLOSED',

  SET_SCREEN = 'SET_SCREEN',
  ON_SUBMIT = 'ON_SUBMIT',
  ON_OPEN = 'ON_OPEN',
  ON_CLOSE = 'ON_CLOSE',
}

export type GlobalStateAction =
  | {
      type: GlobalStateActionType.REFERRAL_LOADING;
    }
  | {
      type: GlobalStateActionType.ON_SUBMIT;
    }
  | {
      type: GlobalStateActionType.ON_OPEN;
    }
  | {
      type: GlobalStateActionType.ON_CLOSE;
    }
  | {
      type: GlobalStateActionType.REFERRAL_CLOSED;
      payload: {
        screen: Screens;
        url: GlobalState['url'];
        optumIframeId?: GlobalState['optumIframeId'];
      };
    }
  | {
      type: GlobalStateActionType.SET_SCREEN;
      payload: {
        screen: Screens;
        url?: GlobalState['url'];
        optumIframeId?: GlobalState['optumIframeId'];
      };
    };
