import { ReferralWithoutPII } from '@getvim/internal-vim-os-sdk/types';
import { getReferralIdMapping } from '@getvim/vim-connect/src/infra/common/api/referral/referral-id-mapping';
import { ReferralExternalIdSource } from '@getvim/platform-types';
import { createLogger } from '../../utils';

const logger = createLogger('getReferralMode');

export enum ReferralModeEnum {
  CREATE = 'create',
  EDIT = 'edit',
}

const getReferralMode = async ({
  referral,
}: {
  referral: ReferralWithoutPII;
}): Promise<ReferralModeEnum> => {
  const { identifiers } = referral ?? {};
  const { ehrReferralId } = identifiers || {};
  try {
    const result = await getReferralIdMapping({
      ehrId: ehrReferralId ?? '',
      source: ReferralExternalIdSource.OPTUM,
    });
    logger.info('Referral mode', { noPHI: true, mode: result ? 'edit' : 'create' });
    return result ? ReferralModeEnum.EDIT : ReferralModeEnum.CREATE;
  } catch (error) {
    logger.error('getReferralIdMapping request failed', {
      ehrId: ehrReferralId,
      err: error,
      noPHI: true,
    });
  }

  return ReferralModeEnum.CREATE;
};

export { getReferralMode };
