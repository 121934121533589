import { Provider } from '@getvim/internal-vim-os-sdk/types';

interface ProviderAnalyticsProperties {
  npi?: string;
  ehr_id?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
}

export const parseVimProviderToAnalyticsProvider = (
  provider?: Partial<Provider>,
): ProviderAnalyticsProperties | undefined => {
  if (!provider) {
    return undefined;
  }

  const { npi, ehrProviderId, demographics, facility } = provider;
  const { firstName, lastName } = demographics ?? {};
  return {
    npi,
    ehr_id: ehrProviderId,
    firstName,
    lastName,
    address: facility?.address?.fullAddress,
  };
};

export const getProviderAnalyticsProperties = (
  previousProvider: Provider | undefined,
  selectedProvider: Partial<Provider> | undefined,
) => {
  return {
    previous_provider: parseVimProviderToAnalyticsProvider(previousProvider),
    selected_provider: parseVimProviderToAnalyticsProvider(selectedProvider),
  };
};
