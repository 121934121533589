import {
  ReferralWithoutPII,
  PatientWithoutPII,
  PatientProblemList,
} from '@getvim/internal-vim-os-sdk/types';
import { internalApi } from '../../api';
import { getReferralNoPIIFormatRequestInput } from './getReferralFormat';

import { ReferralUtilityIframeResult } from '../../api/optum/types';

const referralRequest = async ({
  referral,
  deployConfig,
  patient,
  problemList,
}: {
  referral: ReferralWithoutPII;
  patient: PatientWithoutPII;
  deployConfig: string;
  problemList: PatientProblemList;
}): Promise<ReferralUtilityIframeResult | undefined> => {
  const input = await getReferralNoPIIFormatRequestInput({
    deployConfig,
    currentReferral: referral,
    patient,
    problemList,
  });
  if (input) {
    return await internalApi.optumNoPIIApi.openReferralUtilityIframeNoPII(input);
  }
};

export { referralRequest };

export { isPatientEligible, isReferralValid } from './validation';

export { getReferralMode } from './referralMode';
